<template>
  <div>
    <b-row v-if="catalogue">
      <b-col md="8">
        <b-card v-if="catalogue.images.length">
          <swiper
              ref="swiperTop"
              class="swiper-gallery gallery-top"
              :options="swiperOptions"
              :thumbs="{}"

          >
            <swiper-slide
                v-for="(data,index) in catalogue.images"
                :key="index"
            >
              <b-img
                  :src="data.path"
                  fluid

              />
            </swiper-slide>

            <div
                slot="button-next"
                class="swiper-button-next swiper-button-white"
            />
            <div
                slot="button-prev"
                class="swiper-button-prev swiper-button-white"
            />
          </swiper>
          <swiper
              v-once
              ref="swiperThumbs"
              class="swiper gallery-thumbs"
              :options="swiperOptionThumbs"
              @slide-change="galleryThumbsClickHandler"
          >
            <swiper-slide
                v-for="(data,index) in catalogue.images"
                :key="index"


            >
              <b-img

                  :src="data.path"
                  fluid
              />
            </swiper-slide>
          </swiper>
        </b-card>
        <b-card title="Caractéristiques techniques">
          <b-row>
            <b-col sm="12" class="border-right" md="4">
              <table class="w-100">
                <tr v-if="catalogue.provenance">
                  <td class="font-weight-bold">Provenance</td>
                  <td>{{ catalogue.provenance.libelle }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Première main</td>
                  <td>{{ catalogue.vehicule_premiere_main ? 'Oui' : 'Non' }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Puissance fiscale</td>
                  <td>{{ catalogue.cv_fiscaux }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Puissance réelle</td>
                  <td>{{ catalogue.puissance_din }}</td>
                </tr>

              </table>
            </b-col>
            <b-col sm="12" class="border-right" md="4">
              <table class="w-100">

                <tr>
                  <td class="font-weight-bold">Nombre de portes</td>
                  <td>{{ catalogue.nbr_portes }}</td>
                </tr>
                <tr v-if="catalogue.couleur">
                  <td class="font-weight-bold">Couleur standard</td>
                  <td>{{ catalogue.couleur.libelle }}</td>
                </tr>
                <tr v-if="catalogue.volume_coffre">
                  <td class="font-weight-bold">Taille du coffre</td>
                  <td>{{ catalogue.volume_coffre }}</td>
                </tr>

              </table>
            </b-col>
            <b-col sm="12" md="4">
              <table class="w-100">
                <tr>
                  <td class="font-weight-bold">Capacité du réservoir</td>
                  <td>{{ catalogue.reservoir }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Poids à Vide</td>
                  <td>{{ catalogue.poids_a_vide }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    Poids Total Autorisé en Charge
                  </td>
                  <td>{{ catalogue.ptac }}</td>
                </tr>

              </table>
            </b-col>
          </b-row>

        </b-card>
        <b-tabs>
          <b-tab title="Accessoire">
            <b-card>
              <div v-if="accessoires.length">
                <b-form
                    ref="form"

                    class="repeater-form"
                    @submit.prevent="repeateAccessoire"
                >

                  <!-- Row Loop -->
                  <b-row
                      v-for="(item, index) in accessoires"
                      :id="item.id"
                      :key="item.id"
                      ref="row"
                  >


                    <b-col md="4">
                      <b-form-group
                          label="Libellé"
                          label-for="item-name"
                      >
                        <b-form-input
                            id="item-name"
                            type="text"
                            v-model="item.libelle"
                            placeholder="Libellé"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Cost -->
                    <b-col md="2">
                      <b-form-group
                          label="Prix Unitaire"
                          label-for="cost"
                      >
                        <b-form-input
                            id="cost"
                            type="number"
                            v-model="item.prix_unitaire"
                            placeholder="100"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Quantity -->
                    <b-col md="2">
                      <b-form-group
                          label="Quantité"
                          label-for="quantity"
                      >
                        <b-form-input
                            id="quantity"
                            type="number"
                            v-model="item.qte"
                            placeholder="1"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Profession -->
                    <b-col
                        lg="2"
                        md="1"
                    >
                      <b-form-group
                          label="Total"
                          label-for="price"
                      >
                        <b-form-input
                            id="pzrice"
                            :value="((item.qte && item.prix_unitaire) ? item.qte * item.prix_unitaire : 0) + ' Dhs'"
                            plaintext
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                    >
                      <b-button

                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeAccessoire(index)"
                      >
                        <feather-icon
                            icon="XIcon"
                            class="mr-25"
                        />
                        <span>Supprimer</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">

                    </b-col>
                  </b-row>

                </b-form>
              </div>
              <div v-else class="p-1 text-center">
                Auccun accessoire ajouté
              </div>
              <hr />
              <div class="text-right">
                <b-button
                    size="sm"
                    variant="primary"
                    @click="repeateAccessoire"
                >
                  <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                  />
                  <span>Ajouter</span>
                </b-button>
              </div>

            </b-card>
          </b-tab>
          <b-tab
              active
              title="Frais"
          >
            <div>
              <b-card>
                <div v-if="frais.length">
                  <b-form
                      ref="form"

                      class="repeater-form"
                      @submit.prevent="repeateFrais"
                  >

                    <!-- Row Loop -->
                    <b-row
                        v-for="(item, index) in frais"
                        :id="item.id"
                        :key="item.id"
                        ref="row"
                    >


                      <b-col md="4">
                        <b-form-group
                            label="Libellé"
                            label-for="item-name"
                        >
                          <b-form-input
                              id="item-name"
                              type="text"
                              v-model="item.libelle"
                              placeholder="Libellé"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Cost -->
                      <b-col md="2">
                        <b-form-group
                            label="Prix Unitaire"
                            label-for="cost"
                        >
                          <b-form-input
                              id="cost"
                              type="number"
                              v-model="item.prix_unitaire"
                              placeholder="100"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Quantity -->
                      <b-col md="2">
                        <b-form-group
                            label="Quantité"
                            label-for="quantity"
                        >
                          <b-form-input
                              id="quantity"
                              type="number"
                              v-model="item.qte"
                              placeholder="1"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Profession -->
                      <b-col
                          lg="2"
                          md="1"
                      >
                        <b-form-group
                            label="Total"
                            label-for="price"
                        >
                          <b-form-input
                              id="pzrice"
                              :value="((item.qte && item.prix_unitaire) ? item.qte * item.prix_unitaire : 0) + ' Dhs'"
                              plaintext
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                          lg="2"
                          md="3"
                          class="mb-50"
                      >
                        <b-button

                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeFrais(index)"
                        >
                          <feather-icon
                              icon="XIcon"
                              class="mr-25"
                          />
                          <span>Supprimer</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">

                      </b-col>
                    </b-row>

                  </b-form>
                </div>
                <div v-else class="p-1 text-center">
                  Auccun frais ajouté
                </div>
                <hr/>
                <div class="text-right">
                  <b-button
                      size="sm"
                      variant="primary"
                      @click="repeateFrais"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Ajouter</span>
                  </b-button>
                </div>
        
              </b-card>
    
            </div>


          </b-tab>

          <b-tab
              title="Accompte"

          >

            <b-card>
              <b-form-group label="Accompte" label-for="accompte">
                <b-form-input id="accompte" type="number" v-model="accompte" placeholder="100"/>
              </b-form-group>
              <b-form-group label="Type accompte" label-for="accompte_type">
                <b-form-input id="accompte_type" v-model="accompte_type" :options="[]" label="text"/>
              </b-form-group>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col md="4">
        <b-card
            class=""
            no-body
        >

          <b-card-header>
            <b-card-title>
              {{ catalogue.marque }} {{ catalogue.modele }}
              <br/>
              <span class="font-small-4">{{ catalogue.version }}</span>

            </b-card-title>
            <b-button variant="flat" :to="'/gestion-de-stock/' + catalogue.id" class="btn-icon">
              <feather-icon
                  icon="EyeIcon"
                  size="18"
                  class="cursor-pointer"

              />
            </b-button>

          </b-card-header>

          <b-card-body>
            <div class="d-flex justify-content-between font-small-2 ">
              <div><span class="font-weight-bolder">IMMAT: </span>{{ catalogue.immatriculation }}</div>
              <div><span class="font-weight-bolder">N° D’ENTRÉE: </span>{{ catalogue.num_entree }}</div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between">
              <div>
                <div class="car-icon"
                     :style="{backgroundImage: `url(${require('@/assets/images/cars/compteur-de-vitesse.png')})`}">
                  {{ catalogue.kilometrage }} KM
                </div>
                <div class="car-icon"
                     :style="{backgroundImage: `url(${require('@/assets/images/cars/pompe-a-carburant.png')})`}">
                  {{ catalogue.energie }} KM
                </div>
              </div>
              <div>
                <div class="car-icon"
                     :style="{backgroundImage: `url(${require('@/assets/images/cars/calendar.png')})`}">
                  {{ catalogue.date_mec }}
                </div>
                <div class="car-icon"
                     :style="{backgroundImage: `url(${require('@/assets/images/cars/changement-de-vitesse.png')})`}">
                  {{ catalogue.type_boite }}
                </div>
              </div>
            </div>
            <hr/>
<!--            <div class=" font-medium-1">-->
<!--              {{catalogue.offres_en_cours.length}} Offre{{catalogue.offres_en_cours.length > 1 ? 's' : ''}} en cours  -->
<!--            </div>-->
              
            

          </b-card-body>
        </b-card>
        <b-card>
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                    label="Prix de vente"
                    label-for="prix_de_vente"

                >
                  <b-form-input
                      :class="{'is-invalid' : form_errors['prix_de_vente']}"
                      id="prix_de_vente"
                      placeholder="Prix de vente"
                      v-model="prix_de_vente"
                  />
                  <small v-if="form_errors['prix_de_vente']"
                         class="text-danger">{{ form_errors['prix_de_vente'][0] }}</small>
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>
        </b-card>
        <b-card no-body>
    
          <b-card-body>

            <b-row>

              <!-- first name -->
              <b-col cols="12">
                <b-form-group label="Garantie" label-for="garantie">
                  <v-select id="garantie"
                            :options="$store.state.initialConfigModule.initialState.vehicule_garanties"
                            v-model="garantie"
                            label="libelle"/>
                </b-form-group>

                <b-form-group v-if="false" label="Client" :class="{'is-invalid' : form_errors['client']}" label-for="client">
                  <v-select @search="fetchClients" id="client" v-model="client" :options="clients"
                            label="full_name"/>
                  <small v-if="form_errors['client']" class="text-danger">{{ form_errors['client'][0] }}</small>

                </b-form-group>

              </b-col>

            </b-row>


          </b-card-body>
        </b-card>
 
        <client-card :errors="form_errors.client" @client-selected="clientSelectedHandler" :initial-client="client" />
        <b-card>

          <div class="price-details">
            <h6 class="price-title mb-2">
              Détail
            </h6>
            <ul class="list-unstyled">
              <li class="price-detail mb-1 d-flex justify-content-between">
                <div class="detail-title">
                  Prix
                </div>
                <div class="detail-amt">
                  {{ prix_de_vente }} Dhs
                </div>
              </li>
              <li class="price-detail mb-1 d-flex justify-content-between">
                <div class="detail-title">
                  Accessoire
                </div>
                <div class="detail-amt">
                  {{ total_accessoire }} Dhs
                </div>
              </li>
              <li class="price-detail mb-1 d-flex justify-content-between">
                <div class="detail-title">
                  Frais
                </div>
                <div class="detail-amt ">
                  {{ total_frais }} Dhs
                </div>
              </li>
              <li class="price-detail mb-1 d-flex justify-content-between">
                <div class="detail-title">
                  Accompte
                </div>
                <div class="detail-amt ">
                  {{ accompte }} Dhs
                </div>
              </li>
            </ul>
            <hr>
            <ul class="list-unstyled">
              <li class="price-detail mb-1 d-flex justify-content-between">
                <div class="detail-title detail-total">
                  Net à payer
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{
                    ((prix_de_vente * 1 + total_frais * 1 + total_accessoire * 1) - accompte)
                  }} Dhs
                </div>
              </li>
            </ul>
          </div>

        </b-card>

      </b-col>
      <b-col md="12">
        <b-card>
          <div class="d-flex justify-content-between">
            <div class="text-left">
              <b-dropdown v-if="catalogue.documents.length"
                   
                  split
                  text="Imprimer"
                  variant="primary"
                  right
              >
                <b-dropdown-item v-for="item in catalogue.documents" target="_blank"  :href="item.path">
                  Étiquettes de Prix  
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="text-right">

              <b-button
                  v-if="$can('creer-offre-vente', 'list-bdc')"
                  variant="primary"
                  block
                  @click="creer_offre"
              >
                Créer offre
              </b-button>


            </div>
          </div>
        </b-card>
       
      </b-col>
    </b-row>
    <div v-else class="text-center">
      <b-spinner variant="primary"/>
    </div>
  </div>
</template>


<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {BImg} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import ClientCard from "@/components/ClientCard";

export default {
  components: {
    ClientCard,
    Swiper,
    SwiperSlide,
    BImg,
  },
  computed: {
    total_accessoire() {
      let _this = this;
      let total = 0;
      this.accessoires.forEach(function (item) {
        total += (item.qte * item.prix_unitaire)
      });
      return total;
    },
    total_frais() {
      let _this = this;
      let total = 0;
      this.frais.forEach(function (item) {
        total += (item.qte * item.prix_unitaire)
      });
      return total;
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      catalogue: false,
      accessoires: [],
      frais: [],
      nextTodoId: 2,
      clients: [],
      accompte: '',
      accompte_type: '',
      client: {},
      garantie: {},
      prix_de_vente: 0,
      form_errors: {}
    }
  },
  methods: {
    galleryThumbsClickHandler(index) {
      console.log(index);
      return false;
      this.$refs.swiperTop.$swiper.slideTo(index, 1)

      console.log('--');
    },
    repeateAccessoire() {
      this.accessoires.push({
        id: this.nextTodoId += this.nextTodoId,
        qte: 1
      })
    },
    removeAccessoire(index) {
      this.accessoires.splice(index, 1)
    },
    repeateFrais() {
      this.frais.push({
        qte: 1
      })
    },
    removeFrais(index) {
      this.frais.splice(index, 1)
    },
    fetchClients(search, loading) {
      if (!search) {
        return false;
      }
      let _this = this;
      this.$store.dispatch('clientsModule/fetchClients', {
        'q': search
      }).then(function (response) {
        _this.clients = response.data;
      })


    },
    creer_offre() {
      let _this = this;
      this.$store.dispatch('offreModule/store', {
        type : 1,
        client: _this.client,
        accessoires: _this.accessoires,

        frais: _this.frais,
        accompte: _this.accompte,
        accompte_type: _this.accompte_type,

        ventes: [
          {
            prix: _this.prix_de_vente,
            garantie: _this.garantie,
            vehicule_id: _this.catalogue.id,
          }
        ]
      }).then(function (response) {
        console.log(response);

      }).catch(function (error) {
        if (error.response) {
          _this.form_errors = error.response.data.errors;
        }
      })
    },
    clientSelectedHandler(client){
      this.client = client;
    }
  },
  mounted() {
    let _this = this;
    this.$store.dispatch('catalogueModule/fetch', {
      id: this.$route.params.id
    }).then(function (response) {
      _this.catalogue = response.data;
      _this.prix_de_vente = _this.catalogue.prix_vente_particulier_ttc;
    })
  },

}
</script>

<style>
.gallery-thumbs {
  padding: 10px 0px 0px;
}
</style>